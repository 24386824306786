//
//
//
//
//
//

import BaseCmsBlock from "../../node_modules/sitis-nuxt/lib/cms/CmsBlockMixin";
import Feedback from "~/components/feedback/Feedback.vue";

export default BaseCmsBlock.extend({
  name: "FormBlock",
  components: {
    Feedback,
  },
});
